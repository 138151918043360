<script lang="ts">
import { getProjectMeta } from '~/util'

export const project = defineProject({
  slug: 'barber-tools',
  primary_color: '#D4651F',
  secondary_color: '#3D3D3D',
  header: {
    background: {
      image: `/projekt/barber-tools/elbworx-projekt-barber-tools-header-3648.webp`,
    },
    project: 'BARBER-TOOLS Traditional Shaves',
    headline: 'Gelegt, geschnitten<br> und rasiert!',
    info_card: {
      description: 'Frische Fahrzeug-Folierung für traditionsverliebte Dresdner Barbiere',
      client: 'BARBER-TOOLS Traditional Shaves',
      roles: ['Autofolierung', 'Car-Branding'],
      project_duration: 'Juni/Juli 2022',
    },
  },
})
</script>

<script setup lang="ts">
const { projectPath } = getProjectMeta()
</script>

<template>
  <Project :project="project">
    <template #header_text>
      <h3 class="text-primary">Die Herausforderung</h3>

      <p>
        Julia und Oliver von BARBER-TOOLS haben eine Leidenschaft für exklusive Rasierer und
        hochwertiges Rasierzubehör. Wer bei BARBER-TOOLS kauft, kommt in den Genuss handgefertigter
        Rasurinstrumente, deren Hersteller auf jahrzehntelange Expertise und Erfahrung
        zurückblicken. Das Ergebnis ist herausragende Qualität, die man fühlen und sehen kann!
      </p>
      <p>
        Diese Exklusivität galt es auf das Erscheinungsbild der eigenen Firmenflotte zu übertragen
        —&#32;mit einem neuen Car-Branding, das Haltung und Anspruch des Dresdner Unternehmens
        verkörpert.
      </p>
    </template>

    <Padding class="flex flex-col items-center">
      <div class="max-w-3xl">
        <Image
          class="w-full object-cover py-4"
          :src="`${projectPath}elbworx-referenz-barber-tools-concept-3840.webp`"
        />
        <Text class="mt-12 max-w-full">
          <p>
            Wir haben drei verschiedene Design-Konzepte entwickelt: zunächst fotorealistisch — mit
            diversem Rasierzubehör auf hell-dunklem Streifenmuster, angelehnt an den klassischen
            Barber’s Pole. Dann illustrativ — ein fortlaufendes Muster orangefarbener
            Barbier-Werkzeuge auf dunklem Grund. Und schließlich der Entwurf, der sich die
            Heimatverbundenheit der Barbiere zunutze machte und am Ende auch realisiert wurde: Hier
            bildet ein minimalistischer Stadtplan von Dresden die Leinwand, auf der eine Auswahl von
            Produkten hochwertig in Szene gesetzt wird.
          </p>
        </Text>
      </div>
    </Padding>

    <Grid class="mt-28 items-center justify-center">
      <div class="col-span-8 col-start-3 flex flex-col items-center">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-barber-tools-mercedes-2560.webp`"
        />
        <Text class="mt-16 text-center">
          <p>
            In Zusammenarbeit mit
            <i>Folien Dresden Premium Folierung</i>
            und
            <i>LKS Heymann Digitaldruck & Werbung</i>
            haben wir das heimatverliebte Design auf’s Auto gebracht.
          </p>
        </Text>
      </div>
    </Grid>
    <Grid class="mt-24 items-start">
      <div class="col-span-5 row-span-6">
        <Image
          class="w-full object-cover"
          :src="`${projectPath}elbworx-projekt-barber-tools-mercedes1-1366.webp`"
        />
      </div>
      <div class="col-span-7">
        <Image
          class="w-full"
          :src="`${projectPath}elbworx-projekt-barber-tools-mercedes2-1920.webp`"
        />
      </div>
      <div class="col-span-3">
        <Image
          class="w-full"
          :src="`${projectPath}elbworx-projekt-barber-tools-mercedes3-1366.webp`"
        />
      </div>
    </Grid>
  </Project>
</template>
